<template>
  <div v-loading="loading" style="height: 100vh; width: 100wh;">
   
  </div>
</template>

<script>
import crypto from "@/common/crypto";
export default {
  name: 'befarLogin',
  components:{

  },
  data(){
    return {
      loading:false
    }
  },
  created(){
    this.getUserInfo()
  },
  mounted(){

  },
  methods:{
    getUserInfo(){
      this.loading=true
      this.$instance.get('/getToken',{
          params: {
            ...this.$store.state.basicParams,
            code:this.$route.query.code,
          },
      })
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          try{
            localStorage.setItem('loginResBefar',crypto.encrypt(res.data.info))
            localStorage.setItem('loginStampBefar',new Date().getTime())
          }catch{
              //
          }
          this.$store.commit('loginRes',res.data.info)
          this.$router.replace({
            path: decodeURIComponent(this.$route.query.state) ,
          })
        }else{
          this.$router.replace({
            path: '/noAuth',
          })
        }
      })
      .catch(()=> {
        this.loading=false
        this.$router.replace({
          path: '/error',
        })
      }); 
    },
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
